section {
  padding: 80px 0;
}
.orange-text {
  color: orange;
  font-size: 28px;
}
.welcome-text {
  color: black;
  font-size: 28px;
}
.continue-text {
  color: #000000ba;
  font-size: 20px;
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
}

.modal-body {
  padding: 2.5em 3em;
}

.form-group {
  margin-bottom: 15px;
}

.form-control {
  background: aliceblue;
}

.form-select {
  background-color: aliceblue;
}

.btn.btn-theme-light-2,
.btn.btn-theme-light-2:focus,
.btn.btn-theme-light-2:hover {
  background: rgba(15, 202, 152, .2) !important;
  border: 2px solid rgba(15, 202, 152, .3) !important;
  color: #ca200f !important;
  padding: 16px 50px;
  width: 100%;
}

.theme-bg {
  background: #1266e3;
}

.call-to-act {
  align-items: center;
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.circle-image {
  width: 8%; 
  height: 8%; 
  object-fit: cover; 
  border-radius: 50%; 
}

.btn.btn-call-to-act {
  background: #fff;
  border-radius: 50px;
  box-shadow: 0 0 0 5px hsl(0deg 0% 100% / 20%);
  -webkit-box-shadow: 0 0 0 5px hsl(0deg 0% 100% / 20%);
  color: #333640;
  font-weight: 600;
  padding: 18px 40px;
}

.call-to-act-head {
  flex: 1;
}

.call-to-act-wrap {
  padding: 50px 0;
}

.async-typeahead-container .rbt-input-hint {
  display: block;
  /* Make the placeholder text a block element */
  white-space: pre-line;
  /* Allow line breaks */
}

.input-with-icon::before {
  display: none;
}

.green-text {
  color: green;
}



.google-login {
  background: #fff;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, .25);
  border-radius: 5px;
  color: black;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebook-login {
  background: #3b5998;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, .25);
  border-radius: 5px;
  color: white;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-logo {
  width: 24px;
  margin-right: 10px;
}

.facebook-login:hover {
  background: #416ac2;
  transform: translateY(-5px);
}

.google-login:hover {
  background: white;
  transform: translateY(-5px);
}

.rbt-menu>.dropdown-item {
  overflow: visible;
  text-overflow: ellipsis;
}

