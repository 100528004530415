.page_message_profile_img {
    background: rgb(8, 226, 197);
    border-radius: 50%;
    object-fit: cover;
    aspect-ratio: 1/1;
}

.page_chat_profile_img {
    background: white;
    border-radius: 50%;
    object-fit: cover;
    aspect-ratio: 1/1;
}

/* *******************************
message-area
******************************** */

.professional_page_message-msg-body li {
    list-style: none;
}

.professional_page_message-msg-body li.receiver {
    display: block;
    width: 100%;
    position: relative;
}

.professional_page_message-msg-body li.sender {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
}

.professional_page_message-msg-body li.receiver .professional_page_message-msg-body-list {
    display: inline-block;
    /* background: #f5f5f5; */
    background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%);
    border-radius: 0 10px 10px 10px;
    position: relative;
    color: #000;
}

.professional_page_message-msg-body li.receiver .professional_page_message-msg-body-list ::before {
    content: '';
    position: absolute;
    top: 0;
    left: -5px;
    width: 5px;
    height: 5px;
    background: linear-gradient(225deg, #EADFDF 0%, #EADFDF 50%, transparent 50%, transparent);
}

.professional_page_message-msg-body li.sender .professional_page_message-msg-body-list {
    display: inline-block;
    /* background: linear-gradient(to left, #0099cc 0%, #33cccc 100%); */
    background: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
    position: relative;
    border-radius: 10px 0 10px 10px;
    color: #fff;
}

.professional_page_message-msg-body li.sender .professional_page_message-msg-body-list ::before {
    content: '';
    position: absolute;
    top: 0;
    right: -5px;
    width: 5px;
    height: 5px;
    background: linear-gradient(135deg, #fe9a8b 0%, #fe9a8b 50%, transparent 50%, transparent);
}

.time {
    display: block;
    color: #000;
    font-size: 12px;
    line-height: 1.5;
    font-weight: bold;
}

.professional_page_message-media ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: max-content;
    background: white;
    border-radius: 10px;
    opacity: 0;
    pointer-events: none;
    transform: rotateX(90deg);
    transform-origin: bottom center;
    transition: all 0.3s ease-in-out;
}

.professional_page_message-media ul.open {
    opacity: 1;
    pointer-events: auto;
    transform: rotateX(0deg);
}

.professional_page_message-media ul li {
    opacity: 0;
    animation: dropdown-slide-in 0.3s ease-in-out forwards;
    padding: 12px;
}

.professional_page_message-media ul li:hover {
    background-color: #cde5ff;
    border-radius: 10px;
    padding: 12px;
}

@keyframes dropdown-slide-in {
    0% {
        opacity: 0;
        transform: rotateX(90deg);
    }

    100% {
        opacity: 1;
        transform: rotateX(0deg);
    }
}

.emoji-picker-container {
    display: none;
    position: absolute;
    bottom: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transform: rotateX(90deg);
    transform-origin: bottom center;
    transition: all 0.3s ease-in-out;
    animation: dropdown-slide-in 0.3s ease-in-out forwards;
}

.emoji-picker-container.showEmojiPicker {
    display: block;
    opacity: 1;
    pointer-events: auto;
    transform: rotateX(0deg);
}

.professional_page_message-chat-container {
    display: none;
    opacity: 0;
    transform: rotateX(90deg);
    transform-origin: bottom center;
    transition: all 0.3s ease-in-out;
    animation: show-chat-container 0.8s ease-in-out forwards;
}

.professional_page_message-chat-container.showChatContainer {
    display: block;
    opacity: 1;
    pointer-events: auto;
    transform: rotateX(0deg);
}

@keyframes show-chat-container {
    0% {
        opacity: 0;
        transform: rotateY(90deg);
    }

    100% {
        opacity: 1;
        transform: rotateY(0deg);
    }
}

.professional_page_chat-send-message-button {
    color: white;
    border-radius: 50%;
    background: var(--gradient-2, linear-gradient(90deg, #2AF598 0%, #009EFD 100%));
    border: 0;
    display: inline-block;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

.professional_page_chat-send-message-button:hover {
    transform: translateY(-3px);
}

.professional_page_chat-send-message-button:active {
    transform: translateY(0);
}

.switch-account-horizontal-scrollbar::-webkit-scrollbar {
    height: 5px;
    background-color: #F5F5F5;
}

.switch-account-horizontal-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #555;
}

.professional_page_message-media_preview span {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(255, 0, 0, 0.7);
    color: white;
    border-radius: 5px 0 5px 5px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.professional_page_message-media_preview:hover span {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Radio Button */
.professional_page_chat-radio-inputs {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    border-radius: 1rem;
    background: linear-gradient(145deg, #e6e6e6, #ffffff);
    box-sizing: border-box;
    box-shadow:
        5px 5px 15px rgba(0, 0, 0, 0.15),
        -5px -5px 15px rgba(255, 255, 255, 0.8);
    padding: 0.5rem;
    font-size: 14px;
    gap: 0.5rem;
}

.professional_page_chat-radio-inputs .professional_page_chat-radio {
    flex: 1 1 auto;
    text-align: center;
    position: relative;
}

.professional_page_chat-radio-inputs .professional_page_chat-radio input {
    display: none;
}

.professional_page_chat-radio-inputs .professional_page_chat-radio .professional_page_chat-name {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 0.7rem;
    border: none;
    padding: 0.7rem 0;
    color: #2d3748;
    font-weight: 500;
    font-family: inherit;
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    box-shadow:
        3px 3px 6px rgba(0, 0, 0, 0.1),
        -3px -3px 6px rgba(255, 255, 255, 0.7);
    transition: all 0.2s ease;
    overflow: hidden;
}

.professional_page_chat-radio-inputs .professional_page_chat-radio input:checked+.professional_page_chat-name {
    /* background: linear-gradient(145deg, #3b82f6, #2563eb); */
    background: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%);
    color: white;
    font-weight: 600;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    box-shadow:
        inset 2px 2px 5px rgba(0, 0, 0, 0.2),
        inset -2px -2px 5px rgba(255, 255, 255, 0.1),
        3px 3px 8px rgba(59, 130, 246, 0.3);
    transform: translateY(2px);
}

.professional_page_chat-radio-inputs .professional_page_chat-radio:hover .professional_page_chat-name {
    background: linear-gradient(145deg, #f0f0f0, #ffffff);
    transform: translateY(-1px);
    box-shadow:
        4px 4px 8px rgba(0, 0, 0, 0.1),
        -4px -4px 8px rgba(255, 255, 255, 0.8);
}

.professional_page_chat-radio-inputs .professional_page_chat-radio:hover input:checked+.professional_page_chat-name {
    transform: translateY(1px);
}

.professional_page_chat-radio-inputs .professional_page_chat-radio input:checked+.professional_page_chat-name {
    animation: select 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.professional_page_chat-radio-inputs .professional_page_chat-radio .professional_page_chat-name::before,
.professional_page_chat-radio-inputs .professional_page_chat-radio .professional_page_chat-name::after {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
}

.professional_page_chat-radio-inputs .professional_page_chat-radio input:checked+.professional_page_chat-name::before,
.professional_page_chat-radio-inputs .professional_page_chat-radio input:checked+.professional_page_chat-name::after {
    animation: particles 0.8s ease-out forwards;
}

.professional_page_chat-radio-inputs .professional_page_chat-radio .professional_page_chat-name::before {
    background: #60a5fa;
    box-shadow: 0 0 6px #60a5fa;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.professional_page_chat-radio-inputs .professional_page_chat-radio .professional_page_chat-name::after {
    background: #93c5fd;
    box-shadow: 0 0 8px #93c5fd;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.professional_page_chat-radio-inputs .professional_page_chat-radio .professional_page_chat-name::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background: radial-gradient(circle at var(--x, 50%) var(--y, 50%),
            rgba(59, 130, 246, 0.3) 0%,
            transparent 50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.professional_page_chat-radio-inputs .professional_page_chat-radio input:checked+.professional_page_chat-name::after {
    opacity: 1;
    animation: sparkle-bg 1s ease-out forwards;
}

.professional_page_chat-radio-inputs .professional_page_chat-radio input:checked+.professional_page_chat-name {
    overflow: visible;
}

.professional_page_chat-radio-inputs .professional_page_chat-radio input:checked+.professional_page_chat-name::before {
    box-shadow:
        0 0 6px #f78ca0,
        10px -10px 0 #f78ca0,
        -10px -10px 0 #f78ca0;
    animation: multi-particles-top 0.8s ease-out forwards;
}

.professional_page_chat-radio-inputs .professional_page_chat-radio input:checked+.professional_page_chat-name::after {
    box-shadow:
        0 0 8px #f78ca0,
        10px 10px 0 #f78ca0,
        -10px 10px 0 #f78ca0;
    animation: multi-particles-bottom 0.8s ease-out forwards;
}

@keyframes select {
    0% {
        transform: scale(0.95) translateY(2px);
    }

    50% {
        transform: scale(1.05) translateY(-1px);
    }

    100% {
        transform: scale(1) translateY(2px);
    }
}

@keyframes multi-particles-top {
    0% {
        opacity: 1;
        transform: translateX(-50%) translateY(0) scale(1);
    }

    40% {
        opacity: 0.8;
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) translateY(-20px) scale(0);
        box-shadow:
            0 0 6px transparent,
            20px -20px 0 transparent,
            -20px -20px 0 transparent;
    }
}

@keyframes multi-particles-bottom {
    0% {
        opacity: 1;
        transform: translateX(-50%) translateY(0) scale(1);
    }

    40% {
        opacity: 0.8;
    }

    100% {
        opacity: 0;
        transform: translateX(-50%) translateY(20px) scale(0);
        box-shadow:
            0 0 8px transparent,
            20px 20px 0 transparent,
            -20px 20px 0 transparent;
    }
}

@keyframes sparkle-bg {
    0% {
        opacity: 0;
        transform: scale(0.2);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: scale(2);
    }
}

.professional_page_chat-radio-inputs .professional_page_chat-radio .professional_page_chat-name::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    background: radial-gradient(circle at var(--x, 50%) var(--y, 50%),
            rgba(255, 255, 255, 0.5) 0%,
            transparent 50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.professional_page_chat-radio-inputs .professional_page_chat-radio input:checked+.professional_page_chat-name::before {
    animation: ripple 0.8s ease-out;
}

@keyframes ripple {
    0% {
        opacity: 1;
        transform: scale(0.2);
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
        transform: scale(2.5);
    }
}

.professional_page_chat-radio-inputs .professional_page_chat-radio input:checked+.professional_page_chat-name {
    position: relative;
}

.professional_page_chat-radio-inputs .professional_page_chat-radio input:checked+.professional_page_chat-name::after {
    content: "";
    position: absolute;
    inset: -2px;
    border-radius: inherit;
    background: linear-gradient(45deg,
            rgba(59, 130, 246, 0.5),
            rgba(37, 99, 235, 0.5));
    -webkit-mask:
        linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    animation: border-glow 1.5s ease-in-out infinite alternate;
}

@keyframes border-glow {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

/* Search Box */

.professional_page_chat-search-box {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    border-radius: 1rem;
    position: relative;
}

.professional_page_chat-search-input {
    border: none;
    background: none;
    outline: none;
    color: black;
    font-size: large;
}

.professional_page_chat-search-button {
    color: white;
    position: absolute;
    right: 8px;
    border-radius: 10px;
    background: var(--gradient-2, linear-gradient(90deg, #2AF598 0%, #009EFD 100%));
    /* background: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%); */
    border: 0;
    display: inline-block;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

.professional_page_chat-search-button:hover {
    transform: translateY(-3px);
}

.professional_page_chat-search-button:active {
    transform: translateY(0);
}